$themeColor: #2e6af5;
$themeHoverColor: #044eff;
$customFont: #727273;
$labelColor: #b1b5c3;
$secondaryBg: #353945;
$fontFamily: "Poppins", sans-serif;
$secondaryFont: "DM Sans", sans-serif;
$fontFamilyrtl: "Almarai", sans-serif;
$fontFamilyltr: "Poppins", sans-serif;
$borderRadius: 24px;
$errorColor: #dc3545;
$successColor: #45b26b;
$lightColor: #fcfcfd;
$darkSecColor: #23262f;
$darkbgColor: #141416;
$body-color-2: #F4F5F6;
$white-color: #fff;
$black-color: #000;
$desc-font: #5F6368;
$theme-color2: #22428f;
$text-color: #777E90;
$disccolor:#353840;
// screen sizing 
$def-max-width: 1240px;
@mixin desktop-up {
  @media (min-width: 1400px) {
    @content;
  }
}
@mixin extra_large {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin large_device {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin medium_device {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin mobile_device {
  @media (max-width: 575px) {
    @content;
  }
}
@mixin extra_small {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin headingFont($property, $ltr-value, $rtl-value) {
  [dir="ltr"] & {
    #{$property}: $ltr-value;
  }
  [dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}
@mixin fontWeight($property, $ltr-value, $rtl-value) {
  [dir="ltr"] & {
    #{$property}: $ltr-value;
  }
  [dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}